/**
* @license
*
* Font Family: General Sans
* Designed by: Frode Helland
* URL: https://www.fontshare.com/fonts/general-sans
* © 2024 Indian Type Foundry
*
* General Sans Extralight 
* General Sans ExtralightItalic 
* General Sans Light 
* General Sans LightItalic 
* General Sans Regular 
* General Sans Italic 
* General Sans Medium 
* General Sans MediumItalic 
* General Sans Semibold 
* General Sans SemiboldItalic 
* General Sans Bold 
* General Sans BoldItalic 
* General Sans Variable (Variable font)
* General Sans VariableItalic (Variable font)

*
*/
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: 'GeneralSans';
  src: url('../../assets/fonts/General-Sans/GeneralSans-Variable.woff2') format('woff2'),
       url('../../assets/fonts/General-Sans/GeneralSans-Variable.woff') format('woff'),
       url('../../assets/fonts/General-Sans/GeneralSans-Variable.ttf') format('truetype');
  font-weight: 200 300 400 500 600 700;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: 'GeneralSans';
  src: url('../../assets/fonts/General-Sans/GeneralSans-VariableItalic.woff2') format('woff2'),
       url('../../assets/fonts/General-Sans/GeneralSans-VariableItalic.woff') format('woff'),
       url('../../assets/fonts/General-Sans/GeneralSans-VariableItalic.ttf') format('truetype');
  font-weight: 200 300 400 500 600 700;
  font-display: swap;
  font-style: italic;
}

